<template>
  <div class="">
    <!-- <p>{{ formData }}</p> -->
    <a-form
      ref="formPerson"
      :model="formData"
      :rules="rules"
      :label-col="{ span: 24 }"
      label-align="top"
      :validate-trigger="['change', 'blur']"
    >
      <div class="flex items-center justify-between">
        <a-form-item class="custom-form" label="Avatar" name="images">
          <cds-upload
            accept=".jpg,.png,.jpeg"
            :isMul="true"
            v-model:value="formData.avatar"
            :root-path="ROOT_PATH_MINIO.IDENTITY_PERSON"
          />
        </a-form-item>

        <div class="flex items-center">
          <a-form-item
            class="custom-form"
            :label="$t('identity_object.form.Front Card')"
            name="images"
          >
            <cds-upload
              accept=".jpg,.png,.jpeg"
              :isMul="false"
              v-model:value="formData.personCard.frontCard"
              :root-path="ROOT_PATH_MINIO.IDENTITY_PERSON_CARD"
            />
          </a-form-item>
          <a-form-item
            class="custom-form"
            :label="$t('identity_object.form.Back Card')"
            name="images"
          >
            <cds-upload
              accept=".jpg,.png,.jpeg"
              :isMul="false"
              v-model:value="formData.personCard.backCard"
              :root-path="ROOT_PATH_MINIO.IDENTITY_PERSON_CARD"
            />
          </a-form-item>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-x-16">
        <a-form-item :label="$t('identity_object.form.Name')" name="name">
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.name"
            :maxlength="50"
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Name')"
          />
        </a-form-item>
        <a-form-item :label="$t('identity_object.form.Card Type')" name="name">
          <cds-select
            :options="cardTypes"
            :labelProp="'name'"
            :valueProp="'value'"
            :allowClear="true"
            v-model:value="formData.personCard.type"
          >
          </cds-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.form.Card Code')" name="code">
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.personCard.code"
            :maxlength="12"
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Card Code')"
          />
        </a-form-item>

        <a-form-item :label="$t('identity_object.form.Gender')" name="gender">
          <cds-select
            :options="genders"
            :labelProp="'name'"
            :valueProp="'value'"
            :allowClear="true"
            v-model:value="formData.personCard.gender"
          >
          </cds-select>
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Date of birth')"
          name="dateOfBirth"
        >
          <cds-date-picker
            v-model:value="formData.personCard.dateOfBirth"
            :disabled-date="greaterThanCurrent"
            placeholder=""
          >
          </cds-date-picker>
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Nationality')"
          name="nationality"
        >
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.personCard.nationality"
            :maxlength="50"
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Nationality')"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Age') + '(AI)'"
          name="age"
        >
          <cds-input-number
            class="w-full ant-input-sm"
            size="small"
            v-model:value="formData.age"
            :placeholder="$t('identity_object.form.Age') + '(AI)'"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Gender') + '(AI)'"
          name="gender"
        >
          <cds-select
            :options="genders"
            :labelProp="'name'"
            :valueProp="'value'"
            :allowClear="true"
            v-model:value="formData.gender"
          >
          </cds-select>
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Misshapen')"
          name="misshapen"
        >
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.misshapen"
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Misshapen')"
          />
        </a-form-item>

        <a-form-item :label="$t('role-group.email')" name="email">
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.email"
            :maxlength="254"
            :minlength="5"
            show-count
            size="medium"
            :placeholder="$t('role-group.email')"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Phone number')"
          name="phone"
        >
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.phone"
            :maxlength="12"
            show-count
            size="small"
            :placeholder="$t('identity_object.form.Phone number')"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Organization')"
          name="org"
        >
          <cds-auto-complete
            class="w-full ant-input-sm"
            v-model:value="formData.org"
            :data-source="
              suggestOrganizations.map((item) => ({
                text: item,
                value: item,
              }))
            "
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Organization')"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Staff code')"
          name="staffCode"
        >
          <cds-input
            class="w-full ant-input-sm"
            v-model:value="formData.staffCode"
            :maxlength="20"
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Staff code')"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Department')"
          name="department"
        >
          <cds-auto-complete
            class="w-full ant-input-sm"
            v-model:value="formData.department"
            :data-source="
              suggestDepartments.map((item) => ({
                text: item,
                value: item,
              }))
            "
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Department')"
          />
        </a-form-item>

        <a-form-item
          :label="$t('identity_object.form.Position')"
          name="position"
        >
          <cds-auto-complete
            class="w-full ant-input-sm"
            v-model:value="formData.position"
            :data-source="
              suggestPositions.map((item) => ({
                text: item,
                value: item,
              }))
            "
            show-count
            size="medium"
            :placeholder="$t('identity_object.form.Position')"
          />
        </a-form-item>
      </div>

      <div class="">
        <h2 class="font-bold text-xl">
          {{ $t('identity_object.form.Pemanent Resident') }}
        </h2>
        <form-location
          v-model:address="formData.personCard.addressPermanent.addressDetail"
          v-model:province="formData.personCard.addressPermanent.provinceId"
          v-model:district="formData.personCard.addressPermanent.districtId"
          v-model:ward="formData.personCard.addressPermanent.wardId"
        />

        <h2 class="font-bold text-xl">
          {{ $t('identity_object.form.Place of Origin') }}
        </h2>
        <form-location
          v-model:address="formData.personCard.addressOrigin.addressDetail"
          v-model:province="formData.personCard.addressOrigin.provinceId"
          v-model:district="formData.personCard.addressOrigin.districtId"
          v-model:ward="formData.personCard.addressOrigin.wardId"
        />
      </div>

      <a-form-item :label="$t('identity_object.form.Group')">
        <cds-tree-select
          :checkStrictly="true"
          :tree-data="treeGroup"
          :field-names="fieldNames"
          v-model:value="formData.group"
        />
      </a-form-item>

      <div class="flex items-center gap-x-4 justify-end">
        <cds-button
          @click="onSubmit"
          key="submit"
          type="primary"
          size="small"
          type-html="submit"
        >
          <cds-feather-icons class="mr-10" type="save" />
          {{
            formMode === FORM_MODE.UPDATE
              ? $t('common.update')
              : $t('common.save')
          }}
        </cds-button>
        <router-link to="/identity/object">
          <cds-button type="dark" size="small">
            {{ $t('common.cancel') }}
          </cds-button>
        </router-link>
        <img
          v-if="formMode === FORM_MODE.UPDATE"
          @click="handleDelete()"
          src="@/static/img/icon/trash.svg"
          alt="trash"
          class="w-[32px] h-[32px] cursor-pointer"
        />
      </div>
    </a-form>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FormLocation from './FormLocation.vue';
import CdsInputNumber from '@/components/input-number';
import CdsAutoComplete from '@/components/autoComplete/autoComplete.vue';
import {
  TYPE_IMAGE,
  ROOT_PATH_MINIO,
  FILTER_GROUP_TYPE,
  FILTER_APP_PARAMS,
  FORM_MODE,
  FILTER_SUGGEST_TYPE,
} from '@/config/Constant.js';
import CdsUpload from '@/components/upload';
import { greaterThanCurrent } from '@/util/common-utils';
import { emailRule, maxlength, numberRule, required } from '@/util/validation';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
  formMode: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const { t } = useI18n();

const rules = {
  phone: [
    numberRule(t('role-group.phone')),
    maxlength(12, t('role-group.phone')),
  ],
  email: [required(t('role-group.email')), emailRule(t('role-group.email'))],
};

const formPerson = ref(null);

const getUrlsType = (data, type) => {
  const urls = [];

  data.forEach((item) => {
    if (item.type === type) {
      urls.push(item.url);
    }
  });

  return urls;
};

const fieldNames = { label: 'name', value: 'id' };
const { state, dispatch } = useStore();
const cardTypes = computed(() => state.person.cardTypes);
const treeGroup = computed(() => state.ioGroup.treeGroup);
const suggestPositions = computed(() => state.person.suggestPositions);
const suggestDepartments = computed(() => state.person.suggestDepartments);
const suggestOrganizations = computed(() => state.person.suggestOrganizations);
const formData = reactive(
  Object.assign(
    {},
    {
      ...props.person,
      avatar: props.person.images.map((item) => item.url),
      group: props.person.ioGroups.map((item) => item.groupId),
      personCard: {
        ...props.person.personCard,
        type: props.person.personCard.type
          ? props.person.personCard.type.toString()
          : null,
        frontCard: getUrlsType(
          props.person.personCard.images,
          TYPE_IMAGE.IDENTITY_PERSON_FRONT_CARD
        ),
        backCard: getUrlsType(
          props.person.personCard.images,
          TYPE_IMAGE.IDENTITY_PERSON_BACK_CARD
        ),
      },
    }
  )
);

const handleDelete = async () => {
  let payload = [];
  payload.push({
    id: formData.id,
  });
  const res = await dispatch('person/delete', payload);
  if (res) {
    router.push('/identity/object');
  }
};

const onSubmit = () => {
  try {
    formPerson.value
      .validate()
      .then(async () => {
        const state = {
          age: formData.age,
          department: formData.department,
          email: formData.email,
          gender: formData.gender,
          misshapen: formData.misshapen,
          name: formData.name,
          org: formData.org,
          phone: formData.phone,
          position: formData.position,
          staffCode: formData.staffCode,
          images: formData.avatar.map((url) => ({
            url: url,
            type: TYPE_IMAGE.IDENTITY_PERSON_AVATAR,
            fileName: url && url.substring(url.lastIndexOf('/') + 1),
          })),
          ioGroups: formData.group.map((id) => ({
            groupId: id.value ? id.value : id,
          })),
          personCard: {
            code: formData.personCard.code,
            dateOfBirth: formData.personCard.dateOfBirth,
            gender: formData.personCard.gender,
            nationality: formData.personCard.nationality,
            type: Number(formData.personCard.type),
            addressOrigin: formData.personCard.addressOrigin,
            addressPermanent: formData.personCard.addressPermanent,
            placeOfIssue: formData.personCard.placeOfIssue,
            images: [
              {
                url: formData.personCard.frontCard[0],
                fileName:
                  formData.personCard.frontCard[0] &&
                  formData.personCard.frontCard[0].substring(
                    formData.personCard.frontCard[0].lastIndexOf('/') + 1
                  ),
                type: TYPE_IMAGE.IDENTITY_PERSON_FRONT_CARD,
              },
              {
                url: formData.personCard.backCard[0],
                fileName:
                  formData.personCard.backCard[0] &&
                  formData.personCard.backCard[0].substring(
                    formData.personCard.backCard[0].lastIndexOf('/') + 1
                  ),
                type: TYPE_IMAGE.IDENTITY_PERSON_BACK_CARD,
              },
            ],
          },
        };
        if (props.formMode === FORM_MODE.CREATE) {
          await dispatch('person/createPerson', state);
        } else if (props.formMode === FORM_MODE.UPDATE) {
          const payload = {
            ...state,
            id: formData.id,
            personCard: {
              ...state.personCard,
              id: formData.personCard.id,
            },
          };
          await dispatch('person/updatePerson', payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  } catch {}
};

onMounted(() => {
  const payload = {
    type: FILTER_APP_PARAMS.CARD_TYPE,
  };
  const payloadGroup = {
    type: FILTER_GROUP_TYPE.IO,
  };
  const payloadPosition = {
    column: FILTER_SUGGEST_TYPE.POSITION,
  };
  const payloadDepartment = {
    column: FILTER_SUGGEST_TYPE.DEPARTMENT,
  };
  const payloadOrganization = {
    column: FILTER_SUGGEST_TYPE.ORGANIZATION,
  };
  dispatch('person/getCardTypes', payload);
  dispatch('person/getSuggestPosition', payloadPosition);
  dispatch('person/getSuggestDepartment', payloadDepartment);
  dispatch('person/getSuggestOrganization', payloadOrganization);
  dispatch('ioGroup/getTreeGroup', payloadGroup);
});

const genders = reactive([
  { id: '1', name: 'Male', value: 1 },
  { id: '2', name: 'Female', value: 2 },
]);
</script>
