<template>
  <div class="person-profile">
    <form-object :person="person" :form-mode="formMode" />
  </div>
</template>
<script setup>
import FormObject from './FormObject.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { FORM_MODE } from '@/config/Constant.js';

const props = defineProps({
  formMode: {
    type: String,
    required: true,
  },
});
const route = useRoute();
const { state, dispatch } = useStore();
const person = computed(() => state.person.person);
const fetchData = async () => {
  const payload = {
    id: route.query.idPerson,
  };
  await dispatch('person/personGetData', payload);
};
if (props.formMode === FORM_MODE.UPDATE) {
  await fetchData();
}

await dispatch('location/getAllLocations');
</script>
